<template>
  <div id="app" :class="{ 'white': pageWhite, 'scrolled': scrolled > 30 }" :style="{ 'cursor': scrolled }">
    <div class="router-view">
      <router-view/>
    </div>
  </div>
</template>

<script>
// import LogoSvg from './assets/SE_Logo_White_RGB.svg';
// import LogoSvg from './assets/siemens-logo-default.svg';
export default {
  name: 'App',
  components: { },
  data () {
    return {
      width: 0,
      height: 0,
      scrollTop: -1,
      nav: {
        lineWidth: 0,
        lineX: 0,
        active: -1,
        entries: []
      },
      smoothScroll: false,
      globals: {},
      cookieConsent: false,
      cookieDetails: false,
      year: new Date().getFullYear(),
      header: true,
      footer: true,
      pageWhite: false
    };
  },
  computed: {
    menu: function () {
      return this.$store.getters['scroll/menu'];
    },
    scrolled: function () {
      return this.$store.getters['scroll/scrollTop'];
    }
  },
  methods: {
    resize: function () {
      this.$store.commit('scroll/resize');
      this.resizeNav();
    },
    resizeNav: function () {
      const nav = this.$refs.nav;
      if (nav) {
        const navLinks = nav.querySelectorAll('a');
        const entries = [];
        let active = -1;
        navLinks.forEach((link, i) => {
          const span = link.querySelector('span');
          entries.push({
            width: span.clientWidth,
            offset: link.offsetLeft + span.offsetLeft
          });
          if (link.classList.contains('router-link-active')) {
            active = i;
          }
        });
        this.nav.entries = entries;
        this.nav.active = active;
      }
    },
    focusNav: function (id) {
      this.resizeNav();
      if (this.nav.entries.length && this.nav.entries.length >= id) {
        this.nav.lineWidth = this.nav.entries[id].width;
        this.nav.lineX = this.nav.entries[id].offset;
      }
    },
    blurNav: function () {
      this.resizeNav();
      let width = 0;
      let x = 0;
      if (this.nav.active > -1) {
        width = this.nav.entries[this.nav.active].width;
        x = this.nav.entries[this.nav.active].offset;
      }
      this.nav.lineWidth = width;
      this.nav.lineX = x;
    },
    // get the current scrollTop for all kind of browsers
    getScrollTop: function () {
      let scrollTop = -1;
      if (typeof pageYOffset !== 'undefined') {
        // most browsers except IE before #9
        scrollTop = pageYOffset;
      } else {
        const document = window.document.documentElement; // IE with doctype
        scrollTop = document.clientHeight ? document.scrollTop : window.document.body.scrollTop;
      }
      return scrollTop;
    },
    // smooth-scrollbar scroll
    onScroll: function (data) {
      if (data.offset.y !== this.scrollTop) {
        this.scrollTop = data.offset.y;
        this.$store.commit('scroll/scrollTop', data.offset.y);
      }
    },
    setCookieConsent: function () {
      window.localStorage.setItem('cookieConsent', 'true');
      window.location.reload();
    }
  },
  created () {
    // resize event listener
    window.addEventListener('resize', this.resize); // TODO maybe add resizeend event here?
    this.resize();
    setTimeout(() => this.blurNav(), 100);
    this.unwatchMenu = this.$store.watch(() => this.$store.getters['scroll/menu'], menu => {
      this.resizeNav();
    });
    this.unwatchPageWhite = this.$store.watch(() => this.$store.getters['scroll/whitePage'], pageWhite => {
      this.pageWhite = pageWhite;
      if ('viewer' in window) {
        if (pageWhite) {
          setTimeout(() => {
            window.viewer.pause();
          }, 600); // standard white-page animation duration
        } else {
          window.viewer.run();
        }
      }
    });
  },
  beforeDestroy () {
    this.unwatchMenu();
  }
};
</script>

<style lang="scss">
@import "~normalize.css/normalize.css";
@import "styles/app";
@import "styles/bootstrap";
@import "~swiper/swiper.scss";
</style>
