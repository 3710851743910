<template>
  <div class="showroom__preload" :class="{ 'done': showroomReady }">
    <div class="showroom__preload__text" v-if="loading && !showroomReady" v-html="$t('preload.loading')"></div>
    <div class="showroom__preload__description" v-if="loading && !showroomReady">{{ loadingDescription }}</div>
    <div class="showroom__preload__svg" v-if="loading && !showroomReady">
      <svg class="aui-spinner__svg" viewBox="-24 -24 48 48" width="48" height="48">
        <g class="aui-spinner__group">
          <g class="aui-spinner__group-base"><circle class="aui-spinner__path" cx="0" cy="0" r="22.5"></circle></g>
          <g class="aui-spinner__group-progress" transform="rotate(13.693719574722)">
            <circle class="aui-spinner__path aui-spinner__path--progress" cx="0" cy="0" r="22.5" transform="rotate(-90)" style="stroke-opacity: 1; stroke-dasharray: 0, 141.372; stroke-dashoffset: 0;"></circle>
          </g>
        </g>
      </svg>
    </div>
    <div class="showroom__preload__enter text-center" v-if="!loading">
      <h1 v-html="headline"></h1>
      <div v-if="!redirectToVimeo">
        <img :src="image" :alt="imageAlt" class="showroom__preload__enter__img">
        <div class="row justify-content-center">
          <div class="col-auto mb-5">
            <button
                type="button"
                class="showroom__button"
                @click="$parent.loadShowroom(false)" v-html="$t('preload.enter')"></button>
          </div>
        </div>
        <div class="aui-small mt-4">
          <div v-if="$i18n.locale === 'de'">
            <p>
              {{ $t('preload.browser-title') }}:<br/>
              {{ $t('preload.browser-text') }}
            </p>
            <p>
              {{ $t('preload.mobile') }}<br/>
              {{ $t('preload.size') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preload',
  props: {
    showroomReady: Boolean,
    loading: Boolean,
    loadingDescription: String,
    redirectToVimeo: Boolean,
    headline: String,
    image: String,
    imageAlt: String
  },
  watch: {},
  methods: {},
  mounted () {},
  created () {},
  destroyed () {}
};
</script>

<style lang="scss">
//
</style>
