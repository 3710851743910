<template>
  <div class="showroom vrShowroom home" :style="{ 'background-image': 'url(images/hintergrund-start.jpg)' }">
    <selection ref="selection" :current="''" :show-selection="true" :show-buttons="false" :home="true"></selection>
  </div>
</template>

<script>
import Selection from '@/components/Selection';

export default {
  name: 'Home',
  components: { Selection },
  data () {
    return {};
  },
  computed: {},
  methods: {},
  mounted () {
    if ('de' in this.$route.query) {
      this.$i18n.locale = 'de';
    }
    if ('en' in this.$route.query) {
      this.$i18n.locale = 'en';
    }
  },
  created () {
    this.$store.commit('scroll/setMenu', this.menu);
  },
  beforeDestroy () {
    this.$store.commit('scroll/setWhitePage', false);
    this.$store.commit('scroll/setMenuHidden', false);
    window.removeEventListener('reisze', this.winResize);
  }
};
</script>

<style lang="scss">
.home {
  background: center center no-repeat;
  background-size: cover;
  height: 100vh;
}
</style>
