import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import Plyrue from 'plyrue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueAwesomeSwiper);
Vue.use(Plyrue);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
