<template>
  <div class="admin" :class="{ 'view': showroomReady && !showroom.introPlaying, 'no-events': tour.current > -1 }">

    <div class="admin__icon__holder"
         :class="{ 'active': allinone.open }"
         @click="$parent.toggleAllinone(0)">
      <div class="admin__icon__text" v-html="$t('tour.operation-area')"></div>
      <div class="admin__icon">
        <svg viewBox="0 0 443 389.3" style="enable-background:new 0 0 443 389.3;" xml:space="preserve"><path d="M436.8,83.6l-0.1-0.2c-2.4-3.4-9.4-10.6-23.7-2.5l-0.3,0.2c-60.6,37.8-95.1,58.9-102.3,62.7c-6.3,3.3-14.6,7.3-24.6,11.7
c-27.2-6.7-58.6-10.5-92.3-10.5C86.3,145,2.4,183.5,2.4,232.7c0,2.5,0.2,4.9,0.6,7.3c3.2,141.9,120.6,144.7,184.2,146.3l12.8,0
c58.8-1.4,180.3-4.4,184-145.8c0.5-2.6,0.7-5.2,0.7-7.8c0-20.5-14.6-39.2-39.4-53.9c1.7-2.4,4.4-5.7,8.6-10
c4.2-4.4,30.3-24.5,49.3-39.2c15.9-12.3,25.9-20,29-22.7C438,101.6,443.5,93.4,436.8,83.6z M308.1,350
c-35.4,18.3-79.4,19.3-108.4,20l-12.4,0c-31.3-0.8-74.2-1.8-108.6-19.3c-30.4-15.5-48.9-41.2-56.1-78.1
c31.2,28.6,95.2,47.8,170.9,47.8c75.5,0,139.5-19.1,170.7-47.6C356.9,308.9,338.4,334.3,308.1,350z M22.2,246.3
c4.7-6.5,21.3-16.3,55.2-24.7c33.7-8.4,75.8-13.1,115.5-13.1c85.2,0,157.6,20.8,171,39.7c-17.2,29.2-81.3,56-170.3,56
C102.6,304.1,37.6,276.2,22.2,246.3z M368.2,229.9c-14.6-11.3-38.4-19.1-57.9-24c-34.7-8.8-76.5-13.6-117.5-13.6
c-40.9,0-84.4,5-119.4,13.6c-18.5,4.6-40.2,11.6-54.2,21.4c6.9-32.8,75.2-66,174.3-66C295.3,161.3,364.6,196.2,368.2,229.9z
 M393.2,116.7c-27,20.8-46.4,35.9-51.030,40.8c-4.9,5.1-8.6,9.6-11.3,13.6c-6.6-3.1-13.7-6-21.3-8.6c3.1-1.5,6-3,8.5-4.3
c7.6-4,41.030-24.5,102.2-62.6C416,99.1,404.9,107.7,393.2,116.7z"/><g><path d="M129.6,117.7c-2.5,0-4.9-1.030-6.5-3.2c-0.5-0.7-1.030-1.4-1.8-2.2c-4.1-4.7-10.9-12.5-10.1-26.1c0.5-8.7,5.4-14.7,9.3-19.5
c3.7-4.5,5.9-7.4,5.9-11.3c0-8-6.8-16.4-9.4-19c-3.2-3.1-3.3-8.3-0.1-11.5c3.1-3.2,8.2-3.3,11.5-0.2c1.5,1.4,14.3,14.3,14.3,30.6
c0,9.8-5.3,16.3-9.5,21.5c-3.4,4.2-5.5,6.9-5.7,10.2c-0.4,7,2.7,10.6,6.1,14.4c0.9,1,1.8,2,2.6,3.1c2.7,3.6,2,8.7-1.6,11.4
C133,117.2,131.3,117.7,129.6,117.7z"/></g><g><path d="M249.7,117.7c-2.5,0-4.9-1.030-6.5-3.2c-0.5-0.7-1.030-1.4-1.8-2.2c-4.1-4.7-10.9-12.5-10.1-26.1c0.5-8.7,5.4-14.7,9.3-19.5
c3.7-4.5,5.9-7.4,5.9-11.3c0-8-6.8-16.4-9.4-19c-3.2-3.1-3.3-8.3-0.1-11.5c3.1-3.2,8.2-3.3,11.5-0.2c1.5,1.4,14.3,14.3,14.3,30.6
c0,9.8-5.3,16.3-9.5,21.5c-3.4,4.2-5.5,6.9-5.7,10.2c-0.4,7,2.7,10.6,6.1,14.4c0.9,1,1.8,2,2.6,3.1c2.7,3.6,2,8.7-1.6,11.4
C253.1,117.2,251.4,117.7,249.7,117.7z"/></g><g><path d="M191.4,97.1c-2.5,0-4.9-1.030-6.5-3.2c-0.5-0.7-1.030-1.4-1.8-2.2c-4.1-4.7-10.9-12.5-10.1-26.1c0.5-8.7,5.4-14.7,9.3-19.5
c3.7-4.5,5.9-7.4,5.9-11.3c0-8-6.8-16.4-9.4-19c-3.2-3.1-3.3-8.3-0.1-11.5c3.1-3.2,8.2-3.3,11.5-0.2c1.5,1.4,14.3,14.3,14.3,30.6
c0,9.8-5.3,16.3-9.5,21.5c-3.4,4.2-5.5,6.9-5.7,10.2c-0.4,7,2.7,10.6,6.1,14.4c0.9,1,1.8,2,2.6,3.1c2.7,3.6,2,8.7-1.6,11.4
C194.8,96.6,193.1,97.1,191.4,97.1z"/></g></svg>
      </div>
    </div>

    <div class="admin__icon__holder"
         :class="{ 'active': showroom.metricsOpen }"
         @click="$parent.toggleMetrics(0)">
      <div class="admin__icon__text" v-html="$t('general.dimensions')"></div>
      <div class="admin__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M635.7 165.8L556.1 27.9C550.2 17.7 539.5 12 528.5 12c-5.4 0-10.9 1.4-15.9 4.3L15.9 302.8C.7 311.5-4.5 331 4.3 346.2L83.9 484c5.9 10.2 16.6 15.9 27.6 15.9 5.4 0 10.9-1.4 15.9-4.3L624 209.1c15.3-8.6 20.5-28.1 11.7-43.3zM111.5 468.2L31.9 330.3l69-39.8 43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-43.8-75.8 55.2-31.8 27.9 48.2c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-27.9-48.2 55.2-31.8 43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9L294 179.1l55.2-31.8 27.9 48.2c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-27.9-48.2L432 99.5l43.8 75.8c2.2 3.8 7.1 5.1 10.9 2.9l13.8-8c3.8-2.2 5.1-7.1 2.9-10.9l-43.8-75.8 69-39.8 79.6 137.8-496.7 286.7z"/></svg>
      </div>
    </div>

    <div class="admin__icon__holder"
         :class="{ 'active': performance.open }"
         @click="$parent.togglePerformance(0)">
      <div class="admin__icon__text" v-html="$t('general.performance')"></div>
      <div class="admin__icon">
        <svg width="576px" height="512px" viewBox="0 0 576 512" version="1.030" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(0.000000, 32.000000)" fill="#000000" fill-rule="nonzero">
              <path d="M120,288 C120,301.26 109.26,312 96,312 C82.74,312 72,301.26 72,288 C72,274.74 82.74,264 96,264 C109.26,264 120,274.74 120,288 Z M288,120 C301.26,120 312,109.26 312,96 C312,82.74 301.26,72 288,72 C274.74,72 264,82.74 264,96 C264,109.26 274.74,120 288,120 Z M424,176 C437.26,176 448,165.26 448,152 C448,138.74 437.26,128 424,128 C410.74,128 400,138.74 400,152 C400,165.26 410.74,176 424,176 Z M495.78,285.38 C497.25,294.1 491.34,302.33 482.62,303.79 L351.42,325.66 C348.53,358.3 321.41,384 288,384 C252.65,384 224,355.35 224,320 C224,284.65 252.65,256 288,256 C314.09,256 336.44,271.66 346.41,294.05 L477.38,272.22 C485.91,270.73 494.31,276.66 495.78,285.38 L495.78,285.38 Z M320,320 C320,302.33 305.67,288 288,288 C270.33,288 256,302.33 256,320 C256,337.67 270.33,352 288,352 C305.67,352 320,337.67 320,320 Z M152,128 C138.74,128 128,138.74 128,152 C128,165.26 138.74,176 152,176 C165.26,176 176,165.26 176,152 C176,138.74 165.26,128 152,128 Z M576,288 C576,358.506104 509.5,448 509.5,448 L66.5,448 C55.36,448 44.67,442.42 39.06,432.8 C14.25,390.26 0,340.8 0,288 C0,128.94 128.94,0 288,0 C447.06,0 576,128.94 576,288 Z M557.726929,299.615784 C557.726929,146.962773 429.370728,32 288,32 C146.629272,32 32,156.19008 32,308.84309 C32,357.788084 60,405.900385 82.7,448 L508.83316,448 C531.33316,406.635749 557.726929,348.560777 557.726929,299.615784 Z" id="Shape"></path>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div class="admin__icon__holder">
      <a :href="`lang/${$t(`${model}.pdf`)}`" target="_blank"><div class="admin__icon__text" v-html="$t('general.pdf-download')"></div>
        <div class="admin__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16z"/></svg>
        </div></a>
    </div>

    <div class="admin__icon__holder admin__icon__holder--transparent" @click="$parent.toggleMusic(0)">
      <div class="admin__icon__text" v-html="$t('general.music')"></div>
      <div class="admin__icon">
        <svg v-if="audio.on" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M481.44 0a29.38 29.38 0 0 0-9.25 1.5l-290.78 96C168.72 101.72 160 114 160 128v244.75C143 360 120.69 352 96 352c-53 0-96 35.81-96 80s43 80 96 80 96-35.81 96-80V256l288-96v148.75C463 296 440.69 288 416 288c-53 0-96 35.81-96 80s43 80 96 80 96-35.81 96-80V32c0-18.25-14.31-32-30.56-32zM96 480c-34.69 0-64-22-64-48s29.31-48 64-48 64 22 64 48-29.31 48-64 48zm320-64c-34.69 0-64-22-64-48s29.31-48 64-48 64 22 64 48-29.31 48-64 48zm64-289.72l-288 96V128h-.56v-.12L480 32.62z"/></svg>
        <svg v-if="!audio.on" viewBox="0 0 640 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M637 485.34L23 1.82A7.85 7.85 0 0 0 11.79 3l-10 12.5A7.92 7.92 0 0 0 3 26.73l614 483.48a7.89 7.89 0 0 0 11.0308-1.21l10-12.5a7.82 7.82 0 0 0-1.0308-11.0306zM256 128h-.56v-.12L544 32.62v93.66l-218.7 72.9 30.08 23.69L544 160v148.75C527 296 504.71 288 480 288a112.39 112.39 0 0 0-34.78 5.61l33.63 26.48c.39 0 .76-.09 1.0305-.09 34.69 0 64 22 64 48 0 1-.34 2-.43 3.06l27.33 21.52A67.33 67.33 0 0 0 576 368V32c0-18.25-14.31-32-30.56-32a29.38 29.38 0 0 0-9.25 1.5l-290.79 96c-10.28 3.42-17.68 12.21-20.22 22.83L256 144.6zm-32 244.75C207 360 184.7 352 160 352c-53 0-96 35.81-96 80s43 80 96 80 96-35.81 96-80V266.7l-32-25.2zM160 480c-34.69 0-64-22-64-48s29.32-48 64-48 64 22 64 48-29.3 48-64 48z"/></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminMenu',
  props: {
    model: String,
    showroomReady: Boolean,
    tour: Object,
    allinone: Object,
    performance: Object,
    showroom: Object,
    audio: Object
  },
  watch: {},
  methods: {},
  mounted () {},
  created () {},
  destroyed () {}
};
</script>

<style lang="scss">
//
</style>
