<template>
  <div class="translation">
    <div class="translate">
      <button type="button" class="showroom__button" @click="text2json">&laquo;</button>
      <button type="button" class="showroom__button" @click="json2text">&raquo;</button>
    </div>
    <div class="row position-relative">
      <div class="col-6">
        JSON<br/>
        <textarea v-model="input" rows="40"></textarea>
      </div>
      <div class="col-6">
        EXCEL COPY TEXT<br/>
        <textarea v-model="output" rows="40"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Translation',
  components: {},
  data () {
    return {
      input: '',
      output: ''
    };
  },
  computed: {},
  methods: {
    json2text: function () {
      this.input = this.input.trim();
      if (this.input.substr(-1) === ',') {
        this.input = this.input.substr(0, this.input.length - 1);
      }
      if (this.input.substr(0, 1) !== '{' && this.input.substr(0, 1) !== '[') {
        this.input = `{${this.input}}`;
      }
      const json = JSON.parse(this.input);
      const titles = Object.keys(json);
      const lines = [];
      titles.forEach((title) => {
        if (typeof json[title] === 'string') {
          lines.push(`${title.trim()}\t\t${json[title].trim()}`);
        } else {
          const keys = Object.keys(json[title]);
          keys.forEach((key) => {
            lines.push(`${title.trim()}\t${key.trim()}\t${json[title][key].trim()}`);
          });
        }
      });
      this.output = lines.join('\n');
    },
    text2json: function () {
      this.output = this.output.trim();
      const json = {};
      this.output.split('\n').forEach((line) => {
        const fields = line.split('\t');
        const section = fields[0].trim();
        if (section && fields.length > 2) {
          const identifier = fields[1].trim();
          const value = fields[3].trim();
          if (!(section in json)) {
            json[section] = {};
          }
          // children
          if (identifier) {
            json[section][identifier] = value;
          } else {
            json[section] = value;
          }
        }
      });
      this.input = JSON.stringify(json, null, 2);
    }
  },
  mounted () {},
  created () {
    this.$store.commit('scroll/setMenu', this.menu);
  },
  beforeDestroy () {
    this.$store.commit('scroll/setWhitePage', false);
    this.$store.commit('scroll/setMenuHidden', false);
    window.removeEventListener('reisze', this.winResize);
  }
};
</script>

<style lang="scss">
.translation {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: #eee center center no-repeat;
  background-size: cover;
  padding: 40px;
  font-family: 'Roboto', sans-serif;
  .translate {
    text-align: center;
  }
  textarea {
    width: 100%;
    font-family: "Droid Sans Mono", sans-serif;
    font-size: 14px;
  }
}
</style>
