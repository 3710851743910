import Vue from 'vue';
import VueRouter from 'vue-router';
import ShowroomIVPXS from '@/views/ShowroomIVPXS';
import ShowroomIVPXL from '@/views/ShowroomIVPXL';
import ShowroomIVPL from '@/views/ShowroomIVPL';
import ShowroomIVPS from '@/views/ShowroomIVPS';
import Home from '@/views/Home';
import Translation from '@/views/Translation';

Vue.use(VueRouter);
const routes = [];

routes.push(
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/translate',
    name: 'translate',
    component: Translation
  },
  {
    path: '/ivarioPro2s',
    name: 'ivps',
    component: ShowroomIVPS
  },
  {
    path: '/ivario2xs',
    name: 'ivpxs',
    component: ShowroomIVPXS
  },
  {
    path: '/ivarioProXL',
    name: 'ivpxl',
    component: ShowroomIVPXL
  },
  {
    path: '/ivarioProL',
    name: 'ivpl',
    component: ShowroomIVPL
  }
);

const router = new VueRouter({
  base: '/rational/',
  // mode: 'history',
  routes
});

export default router;
