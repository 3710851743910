<template>
  <div class="showroom vrShowroom">
    <div id="showroom"></div>
    <preload
        :showroom-ready="showroomReady"
        :loading-description="loadingDescription"
        :loading="loading"
        :headline="$t(`${model}.title`)"
        :image-alt="$t(`${model}.title`)"
        :image="'images/ivario-pro-xl/ivario-pro-xl-open-rational-107027.jpg'"></preload>
    <div class="products" :class="{ 'view': showroomReady && !showroom.introPlaying }">
      <div class="products__current">
        <div>{{ $t(`${model}.title`) }}</div>
      </div>
    </div>

    <admin-menu :class="{ 'view': showroomReady && !showroom.introPlaying, 'no-events': tour.current > -1 }"
                :model="model"
                :showroom-ready="showroomReady"
                :showroom="showroom"
                :tour="tour"
                :allinone="allinone"
                :performance="performance"
                :audio="audio"></admin-menu>

    <div class="showroom__video" :class="{ 'view': video.view }">
      <video ref="video" playsinline type="video/mp4" @ended="videoEnded" controls></video>
      <div class="close white" @click="resetAnimations" v-if="tour.current === -1">
        <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
        </svg>
      </div>
    </div>

    <div class="showroom__info showroom__allinone showroom__info showroom__allinone" :class="{ 'view': allinone.view }">
      <div class="showroom__allinone__title">
        <div v-html="$t(`${model}.allinone.title`)"></div>
        <div class="close" @click="toggleAllinone(-1)" v-if="tour.current === -1">
          <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
          </svg>
        </div>
      </div>
      <div class="position-relative showroom__allinone__items" :class="{ 'animate': allinone.animate }">
        <div class="showroom__allinone__item showroom__allinone__item--five"></div>
        <div class="showroom__allinone__item showroom__allinone__item--five">
          <div class="showroom__allinone__item__inner">
            <div class="showroom__allinone__image"><img :src="'images/ivario-pro-l/kipper.webp?v=' + version" width="100%"></div>
            <div>
              <div v-html="$t(`${model}.allinone.kipper`)"></div>
            </div>
          </div>
        </div>
        <div class="showroom__allinone__item showroom__allinone__item--five">
          <div class="showroom__allinone__item__inner">
            <div class="showroom__allinone__image"><img :src="'images/ivario-pro-l/druck.webp?v=' + version" width="100%"></div>
            <div>
              <div v-html="$t(`${model}.allinone.druck`)"></div>
            </div>
          </div>
        </div>
        <div class="showroom__allinone__item showroom__allinone__item--five">
          <div class="showroom__allinone__item__inner">
            <div class="showroom__allinone__image"><img :src="'images/ivario-pro-l/kessel.webp?v=' + version" width="100%"></div>
            <div>
              <div v-html="$t(`${model}.allinone.kessel`)"></div>
            </div>
          </div>
        </div>
        <div class="showroom__allinone__item showroom__allinone__item--five"></div>
      </div>
    </div>

    <div class="showroom__accessories showroom__info" :class="{ 'view': accessories.view }">
      <div class="showroom__info__value" v-html="$t(`${model}.accessories.title`)"></div>
      <div class="aui-small">
        <p v-html="$t(`${model}.accessories.description-1`)"></p>
        <p v-html="$t(`${model}.accessories.description-2`)"></p>
        <p>
          <!--          en: 'https://www.rational-online.com/en_xx/accessories/?show=28&device=556',-->
          <!--          de: ''-->
          <a :href="$t(`${model}.accessories.link`)" target="_blank" class="showroom__button" v-html="$t(`${model}.accessories.linktext`)"></a>
        </p>
      </div>
      <div class="close" @click="accessories.view = false" v-if="tour.current === -1">
        <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
        </svg>
      </div>
    </div>

    <div class="showroom__metrics showroom__info" :class="{ 'view': metrics.view, 'no-events': tour.current > -1 }">
      <div class="row justify-content-end align-items-center">
        <div class="col-auto">
          <svg ref="metrics-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M344.1 470.3l14.2-164.8c-42.1-33.1-70.4-77-70.4-129.5C288 81.7 376.1 0 440 0c22.1 0 40 17.3 40 38.5v435c0 21.2-17.9 38.5-40 38.5h-56c-22.8 0-41.8-18.7-39.9-41.7zM320 176c0 51 32.2 85.5 71.8 114.5L376 473.1c-.3 3.7 3.4 6.9 8 6.9h56c4.3 0 8-3 8-6.5v-435c0-3.5-3.7-6.5-8-6.5-44.6 0-120 65.8-120 144zM240.7 33.8C237.4 14.3 219.5 0 194.6 0c-11.9 0-24.1 3.4-33.3 11.2C152.9 4.1 141.3 0 128 0s-24.9 4.1-33.3 11.2C85.5 3.4 73.3 0 61.4 0 36.2 0 18.6 14.5 15.3 33.8 13.5 43.2 0 118.4 0 149.9c0 50.9 26.7 91.6 71 110.7L59.6 471.4C58.4 493.4 75.9 512 98 512h60c22 0 39.6-18.5 38.4-40.6L185 260.6c44.2-19.1 71-59.8 71-110.7 0-31.5-13.5-106.7-15.3-116.1zM152.3 240l12.2 233.1c.2 3.7-2.7 6.9-6.5 6.9H98c-3.7 0-6.7-3.1-6.5-6.9L103.7 240C61.3 231.2 32 197 32 149.9c0-29.7 14.8-110.6 14.8-110.6 1.6-9.9 28.3-9.7 29.5.2V162c.9 11.5 28.2 11.7 29.5.2l7.4-122.9c1.6-9.7 27.9-9.7 29.5 0l7.4 122.9c1.3 11.4 28.6 11.2 29.5-.2V39.6c1.2-9.9 27.9-10.1 29.5-.2 0 0 14.8 80.9 14.8 110.6.1 46.8-29 81.2-71.6 90z"/></svg>
        </div>
        <div class="col">
          <div class="aui-small" v-html="$t(`${model}.metrics.meals-title`)"></div>
          <div class="showroom__info__value" v-html="$t(`${model}.metrics.meals-value`)"></div>
        </div>
        <div class="col-12"></div>

        <div class="col-auto">
          <svg ref="metrics-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M205.22 22.09C201.21 7.53 188.61 0 175.97 0c-12.35 0-24.74 7.2-29.19 22.09C100.01 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 480c-79.4 0-144-65.54-144-146.09 0-48.36 23-81.32 54.84-126.94 29.18-41.81 65.34-93.63 89.18-170.91 23.83 77.52 60.06 129.31 89.3 171.08C297.06 252.52 320 285.3 320 333.91 320 414.46 255.4 480 176 480zm0-64c-44.12 0-80-35.89-80-80 0-8.84-7.16-16-16-16s-16 7.16-16 16c0 61.75 50.25 112 112 112 8.84 0 16-7.16 16-16s-7.16-16-16-16z"/></svg>
        </div>
        <div class="col">
          <div class="aui-small" v-html="$t(`${model}.metrics.volume-title`)"></div>
          <div class="showroom__info__value" v-html="$t(`${model}.metrics.volume-value`)"></div>
        </div>
        <div class="col-12"></div>

        <div class="col-auto">
          <svg ref="metrics-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80zm320-16v106.667H192V64h128zm160 245.333H352V202.667h128v106.666zm-160 0H192V202.667h128v106.666zM32 202.667h128v106.667H32V202.667zM160 64v106.667H32V80c0-8.837 7.163-16 16-16h112zM32 432v-90.667h128V448H48c-8.837 0-16-7.163-16-16zm160 16V341.333h128V448H192zm160 0V341.333h128V432c0 8.837-7.163 16-16 16H352zm128-277.333H352V64h112c8.837 0 16 7.163 16 16v90.667z"/></svg>
        </div>
        <div class="col">
          <div class="aui-small" v-html="$t(`${model}.metrics.area-title`)"></div>
          <div class="showroom__info__value" v-html="$t(`${model}.metrics.area-value`)"></div>
        </div>
        <div class="col-12"></div>

        <div class="col-auto">
          <svg ref="metrics-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M510.28 445.86l-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-86.74C329.77 114.6 336 98.09 336 80c0-44.18-35.82-80-80-80s-80 35.82-80 80c0 18.09 6.23 34.6 16.36 48h-86.74c-14.43 0-27.08 10.54-30.87 25.72L1.72 445.86C-6.61 479.17 16.38 512 48.03 512h415.95c31.64 0 54.63-32.83 46.3-66.14zM208 80c0-26.47 21.53-48 48-48s48 21.53 48 48-21.53 48-48 48-48-21.53-48-48zm267.27 393.92c-2.17 2.77-5.88 6.08-11.3 6.08H48.03c-5.42 0-9.13-3.3-11.3-6.08-4.29-5.49-5.77-13.08-3.96-20.3l73.03-292.13c.21-.83.52-1.32.43-1.48h299.31c.2.26.47.72.66 1.48l73.03 292.13c1.81 7.22.33 14.81-3.96 20.3z"/></svg>
        </div>
        <div class="col">
          <div class="aui-small" v-html="$t(`${model}.metrics.weight-title`)"></div>
          <div class="showroom__info__value" v-html="$t(`${model}.metrics.weight-value`)"></div>
        </div>
        <div class="col-12"></div>
      </div>
      <div class="close" @click="toggleMetrics(-1)" v-if="tour.current === -1">
        <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
        </svg>
      </div>
    </div>

    <div class="showroom__performance showroom__info" :class="{ 'view': performance.view, 'no-events': tour.current > -1 }">
      <div class="row justify-content-end align-items-center">
        <div class="col-auto">
          <svg ref="performance-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>
        </div>
        <div class="col">
          <div class="showroom__info__value">
            <div class="pb-2" v-html="$t(`${model}.performance.time-1`)"></div>
            <div class="aui-small">{{ $t(`${model}.performance.time-2`) }}<br/>{{ $t(`${model}.performance.time-3`) }}</div>
          </div>
        </div>
        <div class="col-12 pb-5"></div>

        <div class="col-auto">
          <svg ref="performance-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        </div>

        <div class="col">
          <div class="showroom__info__value" v-html="$t(`${model}.performance.example_1`)"></div>
        </div>
        <div class="col-12 pb-1"></div>

        <div class="col-auto">
          <svg ref="performance-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        </div>
        <div class="col">
          <div class="showroom__info__value" v-html="$t(`${model}.performance.example_2`)"></div>
        </div>
        <div class="col-12 pb-1"></div>

        <div class="col-auto">
          <svg ref="performance-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        </div>
        <div class="col">
          <div class="showroom__info__value" v-html="$t(`${model}.performance.example_3`)"></div>
        </div>
        <div class="col-12"></div>

        <div class="col-auto">
          <svg ref="performance-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        </div>
        <div class="col">
          <div class="showroom__info__value"  v-html="$t(`${model}.performance.example_4`)"></div>
        </div>
        <div class="col-12"></div>

        <div class="col-auto">
          <svg ref="performance-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>
        </div>
        <div class="col">
          <div class="showroom__info__value"  v-html="$t(`${model}.performance.example_5`)"></div>
        </div>
        <div class="col-12"></div>
      </div>
      <div class="close" @click="togglePerformance(-1)" v-if="tour.current === -1">
        <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
        </svg>
      </div>
    </div>

    <div v-if="showroomReady" class="hotspots" :class="{ 'no-events': tour.current > -1 }">
      <template v-for="hotspot in showroom.hotspots">
        <div
          :ref="hotspot.ref"
          :key="hotspot.ref"
          :class="{
            visible: (tour.current > -1 && hotspot.forceHover) || (tour.current === -1 && hotspot.visible && !showroom.metricsOpen && !allinone.open && !performance.open),
            left: hotspot.left,
            hover: hotspot.hover || hotspot.forceHover,
            'hotspot--hasInner': (hotspot.video && hotspot.video.hover) || (hotspot.image && hotspot.image.hover),
            'hotspot--video': hotspot.video && hotspot.video.hover,
            'hotspot--image': hotspot.image && hotspot.image.hover,
            'hotspot--bottom': hotspot.position2d.y > winHeight * 0.8 || (winHeight < 900 && hotspot.position2d.y > winHeight * 0.6),
            'hotspot--top': hotspot.position2d.y < winHeight * 0.2,
            'hotspot--left': hotspot.position2d.x < winWidth * 0.3,
            'hotspot--right': hotspot.position2d.x > winWidth * 0.7,
            'hotspot--red': hotspot.red
          }"
          :style="{ transform: 'translate(' + hotspot.position2d.x + 'px, ' + hotspot.position2d.y + 'px)', zIndex: hotspot.position2d.z }"
          @mouseenter="hotspotFocus(hotspot)"
          @mouseleave="hotspotBlur(hotspot)"
          @click="hotspot.click ? hotspot.click(hotspot) : () => {}"
          class="hotspot">
          <div v-if="!hotspot.outlink" class="hotspot__circle"></div>
          <a v-if="hotspot.outlink" :href="hotspot.outlink[$i18n.locale]" target="_blank"><div class="hotspot__circle"></div></a>
          <div class="hotspot__video"
               v-if="hotspot.video && hotspot.video.hover">
            <video loop muted playsinline :ref="hotspot.ref + '-video'">
              <source type="video/mp4" :src="$i18n.locale in hotspot.video.video ? hotspot.video.video[$i18n.locale] : hotspot.video.video.default">
            </video>
          </div>
          <div class="hotspot__image"
               v-if="hotspot.image && hotspot.image.hover">
            <img :src="$i18n.locale in hotspot.image ? hotspot.image[$i18n.locale] : hotspot.image.default" alt="">
          </div>
          <div class="hotspot__inner" v-if="hotspot.description">
            <div v-html="typeof hotspot.description === 'string' ? hotspot.description : hotspot.description[$i18n.locale]"></div>
          </div>
        </div>
      </template>
    </div>

    <template v-for="hotspot in showroom.hotspots">
      <div :key="hotspot.ref + '-overlay'">
        <div class="showroom__overlay" v-if="hotspot.showSlideshow || hotspot.showVideo || hotspot.showMap || hotspot.showLoops" :class="{ 'view': hotspot.display }">
          <div class="showroom__overlay__video" v-if="hotspot.showVideo" :class="{ 'playing': hotspot.showVideo && hotspot.video && hotspot.video.playing }">
            <video class="object-fit object-contain"
                   playsinline :ref="hotspot.ref + '-video'"
                   controls
                   @play="audio.music.volume = 0" @pause="audio.music.volume = audio.on > 0 ? audio.maxVolume : 0" @ended="audio.music.volume = audio.on > 0 ? audio.maxVolume : 0">
              <source :src="$i18n.locale in hotspot.video.video ? hotspot.video.video[$i18n.locale] : hotspot.video.video.default" type="video/mp4" />
            </video>
            <div class="video-play" @click="hotspotToggleVideo(hotspot)">
              <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5,16.4 L29.9,23.4 L19.5,30.6 L19.5,16.4 Z M23.5,45.5 C35.6502645,45.5 45.5,35.6502645 45.5,23.5 C45.5,11.3497355 35.6502645,1.5 23.5,1.5 C11.3497355,1.5 1.5,11.3497355 1.5,23.5 C1.5,35.6502645 11.3497355,45.5 23.5,45.5 Z" stroke="#fff" stroke-width="1" fill="none" fill-rule="evenodd"/>
              </svg>
            </div>
            <div class="slide__text" v-if="hotspot.video.text" style="bottom: 80px;">
              <div v-if="hotspot.video.text" class="slide__info">
                <div v-html="hotspot.video.text[$i18n.locale]"></div>
              </div>
              <div class="slide__title" v-if="hotspot.video.title || hotspot.video.text">
                <div v-if="hotspot.video.text" class="slide__more">
                  <span v-html="$i18n.locale === 'de' ? 'mehr' : 'more'"></span>
                  <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>
                      arrow-narrow-up-small
                    </title>
                    <path d="M18 13.5L12.5 10.5 7 13.5" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
                  </svg>
                </div>
                <h2 v-if="hotspot.video.title && hotspot.video.title[$i18n.locale]" v-html="hotspot.video.title[$i18n.locale]"></h2>
              </div>
            </div>
            <div class="back aui-button aui-button--primary aui-theme-light higher"
                 @click="hotspotOpenSlider(hotspot)"
                 v-if="hotspot.video.button"
                 v-html="hotspot.video.button[$i18n.locale]"></div>
          </div>
          <div class="showroom__overlay__loops" v-if="hotspot.showLoops">
            <div class="showroom__overlay__loops__image">
              <img class="object-fit object-contain" :src="hotspot.loops.image">
            </div>
            <div class="showroom__overlay__loops__video" :class="{ 'view': hotspot.loops.active > -1 }">
              <video muted playsinline :ref="'loops-in'" @ended="hotspot.loops.ended = true; $refs['loops-loop'][0].currentTime = 0;">
                <source v-if="hotspot.loops.active > -1" :src="'/vogue/content/wall-3/' + hotspot.loops.sections[hotspot.loops.active].in" type="video/mp4">
              </video>
              <video muted playsinline :ref="'loops-loop'" @ended="hotspot.loops.active = -1; hotspot.loops.ended = false" :class="{ 'view': hotspot.loops.ended }" class="loop-video">
                <source v-if="hotspot.loops.active > -1" :src="'/vogue/content/wall-3/loop/' + hotspot.loops.sections[hotspot.loops.active].loop[$i18n.locale]" type="video/mp4">
              </video>
            </div>
            <div class="loops__toggle" :class="{ 'view': hotspot.loops.active === -1 }">
              <div
                v-for="(toggle, i) in hotspot.loops.sections"
                :key="'toggle-loops-' + i"
                v-html="toggle.title[$i18n.locale]"
                @click="hotspotLoopVideo(hotspot, i)"></div>
            </div>
            <div class="back aui-button aui-button--primary aui-theme-dark" v-if="hotspot.loops.active > -1" style="bottom: 80px;"
                 @click="hotspot.loops.active = -1; hotspot.loops.ended = false" v-html="hotspot.loops.back[$i18n.locale]"></div>
          </div>

          <swiper v-if="hotspot.slides && hotspot.slides.length && hotspot.sliderOpen" :options="hotspot.sliderOptions" class="showroom__overlay__slider">
            <swiper-slide
              style="height: 100vh; background: #333"
              :key="'overlay-' + hotspot.ref + '-slide-' + i"
              v-for="(slide, i) in hotspot.slides">
              <div v-if="slide.image">
                <div class="slide__bg">
                  <img :src="slide.image" class="object-fit" :class="{ 'object-contain': !slide.imageCover }" title="">
                </div>
              </div>
              <div class="slide__text">
                <div v-if="slide.text" class="slide__info">
                  <div v-html="slide.text"></div>
                </div>
                <div class="slide__title" v-if="slide.title || slide.text">
                  <div v-if="slide.text" class="slide__more">
                    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <title>
                        arrow-narrow-up-small
                      </title>
                      <path d="M18 13.5L12.5 10.5 7 13.5" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
                    </svg>
                  </div>
                  <h2 v-if="slide.title" v-html="slide.title"></h2>
                </div>
              </div>
            </swiper-slide>
            <div class="slide__arrow slide__arrow--prev" slot="button-prev">
              <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M34 27L24.5 21 15 27" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
              </svg>
            </div>
            <div class="slide__arrow slide__arrow--next" slot="button-next">
              <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M34 27L24.5 21 15 27" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
              </svg>
            </div>
          </swiper>
          <div class="close white" @click="hotspot.hideOverlay(hotspot)" v-if="tour.current === -1">
            <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd"/>
            </svg>
          </div>
        </div>
      </div>
    </template>
    <div>
      <div ref="videoPreview" style="height: 0"></div>
    </div>
    <div class="tour" v-if="showroomReady && tour.display" :class="{ 'view': tour.view }">
      <div class="row no-gutters">
        <div class="col-auto align-self-center">
          <div class="showroom__button" @click="tour.current === -1 ? tourSlide(0) : tourEnd()">
            <div v-if="tour.current === -1" v-html="$t('tour.start')"></div>
            <div v-if="tour.current > -1" v-html="$t('tour.stop')"></div>
          </div>
        </div>
        <div class="col-auto align-self-center tour__slide" v-for="(slide, i) in tour.slides" :key="'tour-' + i" :class="{ 'view': tour.current > -1 }">
          <div class="slide" v-if="tour.viewSlides" @click="tourSlide(i)" :class="{ 'active': i === tour.current, 'done': i < tour.current }">
            <div class="progress" :style="{ 'width': slide.progress + '%' }"></div>
            <div class="dot"></div>
            <div class="title">{{ slide.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <selection ref="selection" :current="model" :show-selection="false"></selection>
  </div>
</template>

<script>
import anime from 'animejs';
import Pizzicato from 'pizzicato';
import Selection from '@/components/Selection';
import Preload from '@/components/Preload';
import AdminMenu from '@/components/AdminMenu';

export default {
  name: 'Showroom',
  components: { AdminMenu, Preload, Selection },
  data () {
    return {
      model: 'ivarioProXL', // i18n key
      version: 1,
      isDev: (process.env.NODE_ENV && process.env.NODE_ENV === 'development'),
      redirectToVimeo: false,
      redirectToVimeoCountdown: 10,
      menu: [],
      startDate: new Date('2025-09-11T14:05:00.000Z'),
      chat: false,
      scriptAvailable: false,
      showroomReady: false,
      loading: false,
      loadingVideoPlay: false,
      loadingVideoEnded: true,
      loadingDescription: this.$i18n.t('preload.loading3d'),
      winWidth: 0,
      winHeight: 0,
      mapZoom: 1,
      event: null,
      form: {
        email: '',
        first: '',
        last: '',
        sending: false,
        sent: false,
        error: false,
        mailError: false
      },
      popup: {
        poi: {
          open: false,
          view: false
        },
        routenplaner: {
          open: false,
          view: false
        },
        alexa: {
          open: false,
          view: false
        },
        spracheingabe: {
          open: false,
          view: false
        }
      },
      metrics: {
        open: false,
        view: false
      },
      performance: {
        open: false,
        view: false
      },
      allinone: {
        open: false,
        view: false,
        animate: true
      },
      accessories: {
        open: false,
        view: false
      },
      charging: {
        open: false,
        view: false,
        value: 0
      },
      video: {
        view: false,
        sequence: '',
        dom: null,
        playing: false,
        duration: 0
      },
      audio: {
        on: true,
        maxVolume: 0.2
      },
      tour: {
        display: true,
        view: false,
        viewSlides: false,
        active: false,
        animating: false,
        current: -1,
        timer: 0,
        slides: [
          {
            title: this.$i18n.t('tour.operation-area'),
            enter: () => { this.toggleAllinone(1); },
            leave: () => { this.toggleAllinone(-1); },
            duration: 8000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.dimensions'),
            enter: () => { this.toggleMetrics(1); },
            leave: () => { this.toggleMetrics(-1); },
            duration: 9000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.hours24'),
            enter: () => {
              const hotspot = this.getHotspotByRef('produktionsuhr');
              if (hotspot) {
                hotspot.click(hotspot);
                this.$nextTick(() => {
                  if (this.$refs[`${hotspot.ref}-video`]) {
                    this.$refs[`${hotspot.ref}-video`][0].currentTime = 0;
                    this.$refs[`${hotspot.ref}-video`][0].muted = true;
                    this.$refs[`${hotspot.ref}-video`][0].autoplay = true;
                    this.$refs[`${hotspot.ref}-video`][0].play();
                    hotspot.video.playing = true;
                  }
                });
              }
            },
            leave: () => {
              const hotspot = this.getHotspotByRef('produktionsuhr');
              if (hotspot) {
                hotspot.hideOverlay(hotspot);
                hotspot.video.playing = false;
              }
            },
            duration: this.$t('ivarioPro2XL.uhr').indexOf('.mp3') > -1 ? 22000 : 6000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.cover'),
            enter: () => {
              window.showroom.moveCamera({ alpha: 2.72, beta: 0.95, radius: 27 });
              this.showroom.animationGroups.forEach((group, i) => {
                setTimeout(() => {
                  this.playAnimationGroup(group);
                }, 500 * i);
              });
            },
            leave: null,
            duration: 4000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.heat-elements'),
            enter: () => {
              window.showroom.moveCamera({ alpha: 2.72, beta: 0.95, radius: 27 });
              this.showroom.animationGroups.forEach((group, i) => {
                setTimeout(() => {
                  this.playAnimationGroup(group, true);
                }, 500 * i);
              });
              const hotspot = this.getHotspotByRef('135884_boost');
              // console.log(hotspot);
              if (hotspot) {
                this.hotspotFocus(hotspot, true);
              }
            },
            leave: () => {
              const hotspot = this.getHotspotByRef('135884_boost');
              if (hotspot) {
                this.hotspotBlur(hotspot, true);
              }
            },
            duration: 36000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.multifunctional'),
            enter: () => {
              window.showroom.moveCamera({ alpha: 2.72, beta: 0.95, radius: 27 });
              this.showroom.animationGroups.forEach((group, i) => {
                setTimeout(() => {
                  this.playAnimationGroup(group, true);
                }, 500 * i);
              });
              const hotspot = this.getHotspotByRef('135883_iZoneControl');
              // console.log(hotspot);
              if (hotspot) {
                this.hotspotFocus(hotspot, true);
              }
            },
            leave: () => {
              const hotspot = this.getHotspotByRef('135883_iZoneControl');
              if (hotspot) {
                this.hotspotBlur(hotspot, true);
              }
            },
            duration: 33000,
            progress: 0
          },
          {
            title: this.$i18n.t('tour.autolift'),
            enter: () => {
              window.showroom.moveCamera({ alpha: 2.72, beta: 0.95, radius: 27 });
              this.showroom.animationGroups.forEach((group, i) => {
                setTimeout(() => {
                  this.playAnimationGroup(group, true);
                }, 500 * i);
              });
              const hotspot = this.getHotspotByRef('135882_autolift');
              // console.log(hotspot);
              if (hotspot) {
                this.hotspotFocus(hotspot, true);
              }
            },
            leave: () => {
              const hotspot = this.getHotspotByRef('135882_autolift');
              if (hotspot) {
                this.hotspotBlur(hotspot, true);
              }
            },
            duration: 9000,
            progress: 0
          }
        ]
      },
      showroom: {
        introPlaying: false,
        dom: '#showroom', // in welches DOM element soll das CANVAS gerendert werden?
        gui: false,
        path: process.env.NODE_ENV === 'development' ? 'http://localhost:9009/' : '/viewer/',
        scriptPath: process.env.NODE_ENV === 'development' ? 'http://localhost:9009/' : '/viewer/',
        showroom: true,
        cameraScale: 0.1,
        camera: [0, 5, 0],
        // roomImage: 'assets/Rational_medical_11_Spherical_C.jpg',
        // roomImage: 'assets/CoherentRaum_medical_11_Spherical_C_grau.jpg',
        // roomImage: 'assets/matteo-sphere.png',
        models: [
          {
            path: 'assets/showroom/IVP-XL-P_Fuss_front.glb',
            group: 'IVP_XL',
            position: [0, 0.18, 0],
            fog: false,
            floor: 'ground',
            shadow: {
              src: 'assets/showroom/shadow_xl.webp',
              visibility: 0.5,
              position: [0.1, -0.305, 0.1],
              width: 1.35,
              height: 2.1,
              uScale: 1,
              uOffset: 1,
              vScale: 1,
              vOffset: 1,
              fog: false
            }
          },
          {
            path: 'assets/showroom/Rational_Raum_Blender_v05_2.glb',
            group: 'Raum',
            position: [0, -0.31, 0]
          }
        ],
        screens: [
          {
            ref: 'screen1',
            meshName: 'Display_1',
            click: () => {
              const hotspot = this.getHotspotByRef('screen-1');
              if (hotspot) {
                hotspot.click(hotspot);
              }
            },
            video: {
              src: 'assets/ivario/slideshow.mp4',
              loop: true
            },
            // image: 'assets/ivario/82220_Food_VCC_Tempura_plated_01.TIFF_image-webl.jpg',
            vScale: -1.75,
            target: {}
          },
          {
            ref: 'screen2',
            meshName: 'Display_2',
            click: () => {
              const hotspot = this.getHotspotByRef('produktionsuhr');
              if (hotspot) {
                hotspot.click(hotspot);
              }
            },
            video: {
              src: this.$t('ivarioProXL.uhr').indexOf('.mp4') > -1 ? `assets/ivario/${this.$t('ivarioProXL.uhr')}` : '',
              fromPage: true,
              loop: true
            },
            image: this.$t('ivarioProXL.uhr').indexOf('.mp4') === -1 ? `assets/ivario/${this.$t('ivarioProXL.uhr')}` : '',
            vScale: -1.75,
            target: {}
          },
          {
            ref: 'screen_front_1',
            meshName: 'Print_Front_01',
            video: {
              // src: 'assets/ivario/191781_cut.mp4',
              // loop: true
            },
            image: 'assets/ivario/zubehoer_1_l.webp',
            vScale: -1,
            target: {}
          },
          {
            ref: 'screen_front_2',
            meshName: 'Print_Front_02',
            video: {
              // src: 'assets/ivario/191781_cut.mp4',
              // loop: true
            },
            image: 'assets/ivario/zubehoer_2_l.webp',
            vScale: -1,
            target: {}
          }
          // {
          //   ref: 'screen4',
          //   video: null,
          //   image: 'assets/ivario/100094_Reference_Koepke_DSC00529.tif_image-webl.jpg',
          //   target: {}
          // },
          // {
          //   ref: 'screen5',
          //   video: null,
          //   image: 'assets/ivario/123775_Reference_Falkenstein_7854_iVario_XS_iCombi_Pro_XS.tif_image-webl.jpg',
          //   target: {}
          // }
        ],
        animationGroups: [
          {
            title: {
              de: 'Deckel',
              en: 'Deckel'
            },
            index: 0,
            playing: false,
            open: false
          }
        ],
        scrollFactor: 50,
        hotspots: [
          {
            ref: 'öffnen',
            description: this.$i18n.t('ivarioProXL.hotspots.open'),
            position2d: { x: 0, y: 0, z: 0 },
            red: true,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -0.52, y: 0.55, z: -0.2 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {
              this.showroom.animationGroups.forEach((group, i) => {
                setTimeout(() => {
                  this.playAnimationGroup(group);
                }, 500 * i);
              });
            },
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {}
          },
          {
            ref: 'höhe',
            description: this.$i18n.t('ivarioProXL.hotspots.heightadjustable'),
            position2d: { x: 0, y: 0, z: 0 },
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -0.5, y: -0.25, z: -0.6 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            heightAnimation: {
              running: false,
              position: 0
            },
            click: (hotspot) => {
              // if (!hotspot.heightAnimation.running) {
              //   hotspot.heightAnimation.running = true;
              //   const direction = hotspot.heightAnimation.position === 0 ? 1 : -1;
              //   window.showroom.changeHeight(
              //     ['UG-XS', 'shadow', 'IVP-XS'],
              //     4000,
              //     0.1,
              //     direction,
              //     () => {
              //       hotspot.heightAnimation.running = false;
              //       hotspot.heightAnimation.position = direction === 1 ? 1 : 0;
              //     }
              //   );
              // }
            },
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {
              hover: true,
              playing: false,
              video: {
                default: 'video/151780_iVario_Pro_L_Ergonimic_Height_Adjustment.mp4'
              }
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {}
          },
          {
            ref: '374621',
            description: this.$i18n.t('ivarioProXL.hotspots.setup'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0.5, y: 0.55, z: 0.6 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              hover: true,
              video: {
                default: 'video/iVario_LundXL_Aufstellvarianten.mp4'
              }
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: '110296',
            description: this.$i18n.t('ivarioProXL.hotspots.coldedge'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0.3, y: 0.55, z: -0.1 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            image: {
              hover: true,
              default: 'images/ivario-pro-xl/tiegelrand.jpg'
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'wasser',
            description: this.$i18n.t('ivarioProXL.hotspots.water'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0.3, y: 0.55, z: 1.0 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            image: {
              hover: true,
              default: 'images/ivario-pro-2s/wasser.jpg'
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'entleeren',
            description: this.$i18n.t('ivarioProXL.hotspots.empty'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -0.5, y: 0.55, z: 0.3 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            image: {
              hover: true,
              default: 'images/ivario-pro-xl/entleerung.jpg'
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'clean',
            description: this.$i18n.t('ivarioProXL.hotspots.clean'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -0.5, y: 0.55, z: 0.1 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            image: {
              hover: true,
              default: 'images/ivario-pro-l/clean.webp'
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'pressure',
            description: this.$i18n.t('ivarioProXL.hotspots.pressurecooking'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -0.35, y: 0.55, z: -0.6 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              hover: true,
              video: {
                default: 'video/309450_iVario_Pro_Pressure_Cooking.mp4'
              }
            },
            image: {},
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'screen-1',
            description: this.$i18n.t('ivarioProXL.hotspots.watchmovie'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: -1.5, y: 0.55, z: -5.0 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {
              hotspot.showSlideshow = false;
              hotspot.showVideo = true;
              this.$nextTick(() => {
                setTimeout(() => {
                  hotspot.display = true;
                  const videoRef = hotspot.ref + '-video';
                  if (this.$refs[videoRef]) {
                    this.$refs[videoRef][0].onplay = () => {
                      hotspot.video.playing = true;
                    };
                    this.$refs[videoRef][0].onended = () => {
                      hotspot.video.playing = false;
                    };
                    this.$refs[videoRef][0].onpause = () => {
                      hotspot.video.playing = false;
                    };
                  }
                }, 50);
              });
            },
            hideOverlay: (hotspot) => {
              hotspot.display = false;
              this.$nextTick(() => {
                setTimeout(() => {
                  hotspot.showVideo = false;
                  hotspot.showSlideshow = false;
                }, 520);
              });
            },
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              video: {
                default: 'video/141730_iVario_Pro-de_DE.mp4_master.mp4'
              }
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: '135882_autolift',
            description: this.$i18n.t('ivarioProXL.hotspots.autolift'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0.3, y: 0.55, z: 0.4 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {},
            image: {
              hover: true,
              default: 'images/ivario-pro-xl/autolift.jpg'
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: '135884_boost',
            description: this.$i18n.t('ivarioProXL.hotspots.precision'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0, y: 0.45, z: 0 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              hover: true,
              video: {
                default: 'video/ivarioboost_comp.mp4'
              }
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: '135883_iZoneControl',
            description: this.$i18n.t('ivarioProXL.hotspots.multi'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 0, y: 0.45, z: 0.2 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {},
            hideOverlay: (hotspot) => {},
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              hover: true,
              video: {
                default: 'video/izone_control_comp.mp4'
              }
            },
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'produktionsuhr',
            description: this.$i18n.t('ivarioProXL.hotspots.productionflow'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            position: { x: -5.5, y: 1, z: 2 },
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {
              const videoRef = hotspot.ref + '-video';
              if (this.$refs[videoRef]) {
                hotspot.showSlideshow = false;
                hotspot.showVideo = true;
                this.$nextTick(() => {
                  setTimeout(() => {
                    hotspot.display = true;
                    if (!this.$refs[videoRef][0].dataset.hasCallbacks) {
                      this.$refs[videoRef][0].dataset.hasCallbacks = true;
                      this.$refs[videoRef][0].onplay = () => {
                        hotspot.video.playing = true;
                      };
                      this.$refs[videoRef][0].onended = () => {
                        hotspot.video.playing = false;
                      };
                      this.$refs[videoRef][0].onpause = () => {
                        hotspot.video.playing = false;
                      };
                    }
                    this.$refs[videoRef][0].muted = true;
                    this.$refs[videoRef][0].currentTime = 0;
                    setTimeout(() => {
                      this.$refs[videoRef][0].play();
                    }, 500);
                  }, 50);
                });
              } else {
                hotspot.showSlideshow = true;
                hotspot.sliderOpen = true;
                this.$nextTick(() => {
                  setTimeout(() => {
                    hotspot.display = true;
                  }, 50);
                });
              }
            },
            hideOverlay: (hotspot) => {
              hotspot.display = false;
              this.$nextTick(() => {
                setTimeout(() => {
                  hotspot.showVideo = false;
                  hotspot.showSlideshow = false;
                }, 520);
              });
            },
            showVideo: false,
            showSlideshow: false,
            video: {
              playing: false,
              muted: true,
              video: {
                default: this.$t('ivarioProXL.uhr').indexOf('.mp3') > -1 ? this.$t('ivarioProXL.uhr') : ''
              }
            },
            slides: this.$t('ivarioProXL.uhr').indexOf('.mp3') > -1 ? [] : [
              {
                image: `images/${this.$t('ivarioProXL.uhr')}`,
                imageCover: false,
                title: ''
              }
            ],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          },
          {
            ref: 'accesories',
            description: this.$i18n.t('ivarioProXL.hotspots.accessories'),
            position2d: { x: 0, y: 0, z: 0 },
            zIndex: 100,
            display: false,
            visible: false,
            hover: false,
            forceHover: false,
            left: false,
            position: { x: 5.5, y: 1, z: 0 },
            show: this.hotspotShow,
            hide: this.hotspotHide,
            update: this.hotspotUpdate,
            clickMeshes: [],
            click: (hotspot) => {
              this.accessories.open = true;
              this.accessories.view = true;
            },
            hideOverlay: null,
            outlink: null,
            showVideo: false,
            showSlideshow: false,
            video: {},
            slides: [],
            sliderOpen: false,
            sliderBack: true,
            sliderOptions: {
              navigation: {
                nextEl: '.slide__arrow--next',
                prevEl: '.slide__arrow--prev'
              }
            }
          }
        ],
        metricsBase: [0, -0.3, 0],
        metricsOpen: false,
        metricsAnimating: false,
        metrics: [
          { // breite
            type: 'line',
            group: 'size',
            start: [
              [0.438, 0.434, 0.854 - 1.365],
              [0.438, 0.434, 0.854 - 1.365]
            ],
            end: [
              [0.438, 0.434, 0.854 - 1.365],
              [0.438, 0.434, 0.854 + 0.22]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // breite
            type: 'line',
            group: 'size',
            start: [
              [0.438 - 0.894, 0.434, 0.854 - 1.365],
              [0.438 - 0.894, 0.434, 0.854 - 1.365]
            ],
            end: [
              [0.438 - 0.894, 0.434, 0.854 - 1.365],
              [0.438 - 0.894, 0.434, 0.854 + 0.22]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // breite
            type: 'line',
            group: 'size',
            start: [
              [0.438, 0.434, 0.854 - 1.365],
              [0.438, 0.434, 0.854 - 1.365]
            ],
            end: [
              [0.438, 0.434, 0.854 - 1.365],
              [0.438 - 0.876 - 0.32, 0.434, 0.854 - 1.365]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // breite
            type: 'line',
            group: 'size',
            start: [
              [0.438, 0.434, 0.854],
              [0.438, 0.434, 0.854]
            ],
            end: [
              [0.438, 0.434, 0.854],
              [0.438 - 0.876 - 0.32, 0.434, 0.854]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // hilfslinie höhe
            type: 'line',
            group: 'size',
            start: [
              [0.438, 0.434 + 0.608, 0 - 0.5],
              [0.438, 0.434 + 0.608, 0 - 0.5]
            ],
            end: [
              [0.438, 0.434 + 0.608, 0 - 0.5],
              [0.438, 0.434 + 0.608, 0.854 + 0.2 + 0.22]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // hilfslinie höhe
            type: 'line',
            group: 'size',
            start: [
              [0.18, 1.704, 0.854 - 1.365 - 0.22],
              [0.18, 1.704, 0.854 - 1.365 - 0.22]
            ],
            end: [
              [0.18, 1.704, 0.854 - 1.365 - 0.22], // 1.36585 - 0.045 - 0.595
              [0.18, 1.704, 0]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          { // hilfslinie höhe
            type: 'line',
            group: 'size',
            start: [
              [0.18, 0.434, 0.854 - 1.365 - 0.22],
              [0.18, 0.434, 0.854 - 1.365 - 0.22]
            ],
            end: [
              [0.18, 0.434, 0.854 - 1.365 - 0.22], // 1.36585 - 0.045 - 0.595
              [0.18, 0.434, 0.854 - 1.365]
            ],
            duration: 500,
            delay: 0,
            easing: 'easeOutQuad',
            color: [0, 0, 0],
            radius: 1
          },
          {
            type: 'combo',
            group: 'size',
            center: [0.438, 0.434 + 0.608 / 2, 0.854 + 0.2],
            lineColor: [0, 0, 0],
            direction: 'y',
            width: 0.608,
            radius: 2,
            arrows: true,
            text: this.unit(608),
            fontSize: 60,
            fontWeight: 'normal',
            height: 0.08,
            billboard: 'Y',
            color: '#000',
            rotation: [0, 0, Math.PI / -2],
            duration: 500,
            delay: 600,
            easing: 'easeOutQuad'
          },
          {
            type: 'combo',
            group: 'size',
            center: [0.438, 1.040 / 2, 0.854 + 0.2 + 0.2],
            lineColor: [0, 0, 0],
            direction: 'y',
            width: 1.050,
            radius: 2,
            arrows: true,
            text: this.unit(1078),
            fontSize: 60,
            fontWeight: 'normal',
            height: 0.08,
            billboard: 'Y',
            color: '#000',
            rotation: [0, 0, Math.PI / -2],
            duration: 500,
            delay: 600,
            easing: 'easeOutQuad'
          },
          {
            type: 'combo',
            group: 'size',
            center: [0.18, 0.434 + (1.704 - 0.434) / 2, 0.854 - 1.365 - 0.2],
            lineColor: [0, 0, 0],
            direction: 'y',
            width: 1.704 - 0.434,
            radius: 2,
            arrows: true,
            text: this.unit(1270),
            fontSize: 60,
            fontWeight: 'normal',
            height: 0.08,
            billboard: 'Y',
            color: '#000',
            rotation: [0, 0, Math.PI / -2],
            duration: 500,
            delay: 600,
            easing: 'easeOutQuad'
          },
          {
            type: 'combo',
            group: 'size',
            center: [0.438 - 0.894 / 2, 0.434, 0.854 + 0.2],
            lineColor: [0, 0, 0],
            direction: 'x',
            width: 0.894,
            radius: 2,
            arrows: true,
            text: this.unit(894),
            fontSize: 60,
            fontWeight: 'normal',
            height: 0.08,
            billboard: '',
            color: '#000',
            rotation: [Math.PI / 2, Math.PI, 0],
            duration: 500,
            delay: 600,
            easing: 'easeOutQuad'
          },
          {
            type: 'combo',
            group: 'size',
            center: [0.438 - 0.876 - 0.3, 0.434, 0.854 - 1.365 / 2],
            lineColor: [0, 0, 0],
            direction: 'z',
            width: 1.365,
            radius: 2,
            arrows: true,
            text: this.unit(1365),
            fontSize: 60,
            fontWeight: 'normal',
            height: 0.087,
            billboard: '',
            color: '#000',
            rotation: [Math.PI / 2, Math.PI, Math.PI / 2],
            duration: 500,
            delay: 600,
            easing: 'easeOutQuad'
          }
        ]
      }
    };
  },
  computed: {
    mapDimensions: function () {
      // width="1920px" height="1198px"
      const oW = 1920;
      const oH = 1198;
      const r = oW / oH;
      let h = this.winHeight;
      let w = h * r;
      if (w < this.winWidth) {
        w = this.winWidth;
        h = w / r;
      }
      return { w: w, h: h };
    },
    modelKey: function () {
      return this.model;
    }
  },
  methods: {
    unit: function (val) {
      let value = val / (this.$t('unit') === 'mm' ? 1 : 25.4);
      if (value !== Math.round(value)) {
        value = Math.round(value * 10) / 10;
      }
      return `${value} ${this.$t('unit')}`;
    },
    getHotspotByRef: function (ref) {
      return this.showroom.hotspots.find((h) => h.ref === ref);
    },
    tourEnd: function () {
      if (this.tour.slides[this.tour.current].leave) {
        this.tour.slides[this.tour.current].leave();
      }
      this.tour.slides.forEach((slide, i) => {
        anime.remove(slide);
        anime({
          targets: slide,
          progress: 0,
          duration: 500,
          easing: 'easeOutCubic'
        });
      });
      this.tour.current = -1;
    },
    tourSlide: function (index) {
      let timeout = 0;
      if (this.tour.current !== index && index < this.tour.slides.length) {
        if (this.tour.current > -1) {
          if (this.tour.slides[this.tour.current].leave) {
            this.tour.slides[this.tour.current].leave();
            timeout = 1000;
          }
        }
        this.tour.current = index;
        this.tour.slides.forEach((slide, i) => {
          anime.remove(slide);
          anime({
            targets: slide,
            progress: index > i ? 100 : 0,
            duration: 500,
            easing: 'easeOutCubic'
          });
        });
        setTimeout(() => {
          this.tour.slides[this.tour.current].enter();
          anime.remove(this.tour.slides[this.tour.current]);
          anime({
            targets: this.tour.slides[this.tour.current],
            progress: 100,
            duration: this.tour.slides[this.tour.current].duration,
            easing: 'linear',
            complete: () => {
              const next = this.tour.current + 1;
              if (next < this.tour.slides.length) {
                this.tourSlide(next);
              } else {
                this.tourEnd();
              }
            }
          });
        }, timeout);
      }
    },
    toggleMusic: function (mode = 0) {
      if (mode === 0) {
        mode = this.audio.on ? -1 : 1;
      }
      this.audio.on = mode === 1;
      // console.log('toggleMusic', mode, this.audio.on);
      anime({
        targets: this.audio.music,
        volume: this.audio.on ? this.audio.maxVolume : 0,
        duration: 500,
        easing: 'linear'
      });
    },
    toggleAllinone: function (mode = 0) {
      if (mode === 0) {
        mode = this.allinone.view ? -1 : 1;
      }
      if (mode === 1) {
        this.accessories.open = false;
        this.accessories.view = false;
        if (this.showroom.metricsOpen) {
          this.toggleMetrics(-1);
          setTimeout(() => {
            this.toggleAllinone(1);
          }, 200);
        } else {
          if (this.performance.open) {
            this.togglePerformance(-1);
          }
          this.accessories.open = false;
          this.accessories.view = false;
          this.allinone.open = true;
          this.allinone.view = true;
          setTimeout(() => {
            this.allinone.animate = false;
          }, 500);
        }
      } else {
        this.allinone.animate = true;
        this.allinone.open = false;
        this.allinone.view = false;
      }
    },
    // 0 = toggle. 1 = show. -1 = hide
    togglePerformance: function (mode = 0) {
      if (mode === 0) {
        mode = this.performance.view ? -1 : 1;
      }
      window.console.log('togglePerformance', mode);
      if (mode === 1 && this.showroom.metricsOpen) {
        this.accessories.open = false;
        this.accessories.view = false;
        this.toggleMetrics(-1);
        setTimeout(() => {
          this.togglePerformance(mode);
        }, 200);
      } else {
        if (mode === 1) {
          this.toggleAllinone(-1);
          this.accessories.open = false;
          this.accessories.view = false;
          const refs = [];
          for (const [key, value] of Object.entries(this.$refs)) {
            if (key.substr(0, 12) === 'performance-') {
              refs.push(value);
            }
          }
          this.performance.open = true;
          this.performance.view = true;
          refs.forEach((ref, index) => {
            anime({
              targets: ref.querySelectorAll('path'),
              strokeDashoffset: [anime.setDashoffset, 0],
              fillOpacity: [0, 0],
              strokeOpacity: [1, 1],
              strokeWidth: [10, 10],
              stroke: ['#000000', '#000000'],
              easing: 'easeInOutSine',
              duration: 900,
              delay: function (el, i) {
                return 500 + index * 200;
              },
              complete: () => {
                anime({
                  targets: ref.querySelectorAll('path'),
                  strokeOpacity: 0,
                  fillOpacity: 1,
                  easing: 'easeOutCubic',
                  duration: 500
                });
              }
            });
          });
        } else {
          this.performance.open = false;
          this.performance.view = false;
        }
      }
    },
    toggleMetrics: function (mode = 0) {
      if ('showroom' in window && window.showroom.params.ready) {
        if (!this.showroom.metricsAnimating) {
          this.showroom.metricsAnimating = true;
          if (mode === 0) {
            mode = this.showroom.metricsOpen ? -1 : 1;
          }
          if ((mode === -1 && this.metrics.view) || (mode === 1 && !this.metrics.view)) {
            window.showroom.animateLine(-1, mode);
          }
          // console.log(mode, this.$refs);
          const refs = [];
          for (const [key, value] of Object.entries(this.$refs)) {
            if (key.substr(0, 8) === 'metrics-') {
              refs.push(value);
            }
          }
          if (mode === 1) {
            anime({
              targets: this.audio.lowPass,
              frequency: 400,
              duration: 2000,
              easing: 'linear'
            });
            this.accessories.open = false;
            this.accessories.view = false;
            this.toggleAllinone(-1);
            this.togglePerformance(-1);
            this.playAnimationGroup(this.showroom.animationGroups[0], true);
            window.showroom.moveCamera({ alpha: 2.79, beta: 1.02, radius: 30 });
            anime({
              targets: window.showroom.scene,
              fogDensity: 0.2,
              duration: 2000,
              easing: 'linear'
            });
            refs.forEach((ref, index) => {
              this.metrics.view = true;
              anime({
                targets: ref.querySelectorAll('path'),
                strokeDashoffset: [anime.setDashoffset, 0],
                fillOpacity: [0, 0],
                strokeOpacity: [1, 1],
                strokeWidth: [10, 10],
                stroke: ['#000000', '#000000'],
                easing: 'easeInOutSine',
                duration: 900,
                delay: function (el, i) {
                  return 1500 + index * 200;
                },
                complete: () => {
                  this.showroom.metricsAnimating = false;
                  this.showroom.metricsOpen = true;
                  anime({
                    targets: ref.querySelectorAll('path'),
                    strokeOpacity: 0,
                    fillOpacity: 1,
                    easing: 'easeOutCubic',
                    duration: 500
                  });
                }
              });
            });
          } else {
            if (this.showroom.metricsOpen) {
              this.playAnimationGroup(this.showroom.animationGroups[0], false);
              this.metrics.view = false;
              anime({
                targets: this.audio.lowPass,
                frequency: 22050,
                duration: 2000,
                easing: 'linear'
              });
              anime({
                targets: window.showroom.scene,
                fogDensity: 0,
                duration: 2000,
                easing: 'linear',
                complete: () => {
                  this.showroom.metricsAnimating = false;
                  this.showroom.metricsOpen = false;
                  window.showroom.checkMeshAfterUpdate = false;
                  window.showroom.checkMesh();
                }
              });
              refs.forEach((ref, index) => {
                anime({
                  targets: ref.querySelectorAll('path'),
                  strokeDashoffset: [0, anime.setDashoffset],
                  easing: 'easeInOutSine',
                  duration: 500
                });
              });
            }
          }
        }
      }
    },
    togglePopup: function (name, mode = 0, refs = []) {
      if (mode === 0) {
        mode = this.popup[name].view ? -1 : 1;
      }
      if ((mode === -1 && this.popup[name].view) || (mode === 1 && !this.popup[name].view)) {
        // window.showroom.animateLine(-1, mode);
      }
      if (!refs.length) {
        refs.push(name);
      }
      if (mode === 1 && !this.popup[name].view) {
        setTimeout(() => {
          this.popup[name].view = true;
          refs.forEach((refName, index) => {
            const ref = this.$refs[refName];
            if (ref) {
              anime({
                targets: ref.querySelectorAll('path'),
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 900,
                delay: function (el, i) { return 1000 + index * 200; }
              });
            }
          });
        }, 1500);
      } else if (mode === -1 && this.popup[name].view) {
        refs.forEach((refName, index) => {
          const ref = this.$refs[refName];
          if (ref) {
            anime({
              targets: ref.querySelectorAll('path'),
              strokeDashoffset: [0, anime.setDashoffset],
              easing: 'easeInOutSine',
              duration: 500,
              complete: () => {
                this.popup[name].view = false;
              }
            });
          }
        });
      }
    },
    playIntro: function () {
      this.showroom.introPlaying = true;
      // this.introAudio.play();
      // window.showroom.playIntro(() => {
      //   this.showroom.introPlaying = false;
      // });
      this.showroom.introPlaying = false;
      setTimeout(() => {
        this.tour.view = true;
        setTimeout(() => {
          this.tour.viewSlides = true;
        }, 1000);
      }, 1000);
      window.showroom.introComplete();
    },
    showVideo: function (src, sequence = '', loop = false, volume = 0.7, callback = null) {
      this.videoCallback = callback;
      const video = this.$refs.video;
      this.video.view = true;
      this.video.sequence = sequence;
      video.src = src;
      video.currentTime = 0;
      video.autoplay = true;
      if (loop) {
        video.setAttribute('loop', '');
      } else {
        video.removeAttribute('loop');
      }
      video.volume = volume;
      video.play();
    },
    hideVideo: function () {
      const video = this.$refs.video;
      video.pause();
      this.video.view = false;
      this.video.sequence = '';
    },
    videoEnded: function () {
      if (this.videoCallback) {
        this.videoCallback();
      } else {
        this.hideVideo();
      }
    },
    toggleInterior: function () {
      window.showroom.camera.radius < 1 ? this.playSequence('null') : this.playSequence('v6');
    },
    resetAnimations: function () {
      this.socket.currentSequence = '';
      this.toggleMetrics(-1);
      this.hideVideo();
    },
    openerComplete: function () {
      this.loadingVideoPlay = false;
      this.loadingVideoEnded = true;
      const video = this.$refs.opener;
      if (video) {
        video.pause();
      }
    },
    validateEmail: function (email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    checkLeadForm: function () {
      let error = false;
      // necessary?
      this.form.email = this.form.email.trim();
      this.form.first = this.form.first.trim();
      this.form.last = this.form.last.trim();
      if (!this.form.email || (this.form.email && !this.validateEmail(this.form.email))) {
        error = true;
        this.form.mailError = true;
      } else {
        this.form.mailError = false;
      }
      if (!this.form.first || !this.form.last) {
        error = true;
      }
      this.form.error = error;
    },
    loadShowroom: function (email = false) {
      // check lead form data if necessary
      // let needsLead = false;
      // if (email && this.event) {
      //   needsLead = true;
      //   if (this.form.sent && this.form.event === this.socket.channel) {
      //     needsLead = false;
      //   }
      //   this.checkLeadForm();
      // } else {
      //   this.form.error = false;
      // }
      // datenschutz aktiv
      this.form.error = false;
      if (!this.form.error) {
        // if (needsLead) {
        //   this.$cookies.set('lead', JSON.stringify({
        //     event: this.socket.channel,
        //     email: this.form.email,
        //     first: this.form.first,
        //     last: this.form.last
        //   }));
        //   // fetch('http://localhost:8888/api/mail', {
        //   fetch('https://e-tron-gt-virtual.com/api/mail', {
        //     method: 'POST',
        //     referrerPolicy: 'no-referrer-when-downgrade',
        //     mode: 'no-cors',
        //     body: JSON.stringify({
        //       event: this.socket.channel,
        //       email: this.form.email,
        //       first: this.form.first,
        //       last: this.form.last
        //     })
        //   });
        // }
        // load showroom
        window.console.log('loadShowroom');
        this.silence.play();
        this.audio.music.volume = this.audio.maxVolume;
        this.audio.music.play();
        if (window.showroom) {
          this.loading = true;
          window.showroom.init();
          this.loadingInterval = setInterval(() => {
            this.loadingDescription = window.showroom.loadingDescription;
          }, 300);
        }
      }
    },
    hotspotShow: function (hotspot) {
      // window.console.log('show', hotspot);
    },
    hotspotHide: function (hotspot) {
      // window.console.log('hide', hotspot);
    },
    hotspotFocus: function (hotspot, forceHover = false) {
      hotspot.hover = true;
      if (forceHover) {
        hotspot.forceHover = forceHover;
      }
      if (hotspot.video && hotspot.video.hover) {
        this.$refs[hotspot.ref + '-video'][0].seek = 0;
        this.$refs[hotspot.ref + '-video'][0].play();
      }
    },
    hotspotBlur: function (hotspot, removeForceHover = false) {
      if (hotspot.forceHover) {
        if (removeForceHover) {
          hotspot.forceHover = false;
          hotspot.hover = false;
        }
      } else {
        hotspot.hover = false;
      }
      if (hotspot.video && hotspot.video.hover) {
        if (!hotspot.hover && !hotspot.forceHover) {
          this.$refs[hotspot.ref + '-video'][0].pause();
        }
      }
    },
    hotspotUpdate: function (ref, x, y) {
      window.console.log('hotspotShow', ref, x, y);
      const hotspot = this.showroom.hotspots.find((h) => h.ref === ref);
      if (hotspot) {
        window.console.log(hotspot);
      }
    },
    hotspotToggleVideo: function (hotspot) {
      const videoRef = hotspot.ref + '-video';
      if (this.$refs[videoRef]) {
        const playing = this.$refs[videoRef][0].playing;
        if (playing) {
          this.$refs[videoRef][0].pause();
        } else {
          this.$refs[videoRef][0].play();
        }
        hotspot.video.playing = playing;
      }
    },
    hotspotLoopVideo: function (hotspot, i) {
      hotspot.loops.active = i;
      hotspot.loops.ended = false;
      this.$nextTick(() => {
        this.$refs['loops-in'][0].currentTime = 0;
        this.$refs['loops-in'][0].load();
        this.$refs['loops-in'][0].play();
        this.$refs['loops-loop'][0].load();
        this.$refs['loops-loop'][0].play();
      });
    },
    hotspotOpenSlider: function (hotspot) {
      hotspot.sliderOpen = true;
      const videoRef = hotspot.ref + '-video';
      if (this.$refs[videoRef]) {
        this.$refs[videoRef][0].pause();
        hotspot.video.playing = false;
      }
    },
    playAnimationGroup: function (group, open = null) {
      window.console.log('playAnimationGroup', group);
      const ani = window.showroom.scene.animationGroups[group.index];
      if (!group.callbacks) {
        ani.onAnimationGroupPlayObservable.add(() => {
          window.console.log('group playing');
          group.playing = true;
        });
        ani.onAnimationGroupEndObservable.add(() => {
          window.console.log('group end');
          window.showroom.checkMeshAfterUpdate = false;
          window.showroom.checkMesh();
          group.playing = false;
        });
        ani.onAnimationGroupPauseObservable.add(() => {
          window.console.log('group pause');
          window.showroom.checkMeshAfterUpdate = false;
          window.showroom.checkMesh();
          group.playing = false;
        });
        group.callbacks = true;
      }
      if (!group.playing) {
        const from = !group.open ? ani.from : ani.to;
        const to = !group.open ? ani.to : ani.from;
        if (open !== null) {
          if (open && !group.open) {
            group.open = !group.open;
            ani.start(false, 1, from, to);
          } else if (!open && group.open) {
            group.open = !group.open;
            ani.start(false, 1, from, to);
          }
        } else {
          group.open = !group.open;
          ani.start(false, 1, from, to);
        }
      }
    },
    winResize: function () {
      this.winWidth = window.innerWidth;
      this.winHeight = window.innerHeight;
    }
  },
  mounted () {
    if ('de' in this.$route.query) {
      this.$i18n.locale = 'de';
    }
    if ('en' in this.$route.query) {
      this.$i18n.locale = 'en';
    }
    window.console.log('en' in this.$route.query);
    window.viewerSettings = this.showroom;
    const showroomScript = document.createElement('script');
    const date = new Date();
    showroomScript.setAttribute('src', this.showroom.scriptPath + 'app.js?nocache=' + date.getMilliseconds());
    document.head.appendChild(showroomScript);

    this.silence = new Audio('audio/silence.mp3');
    Pizzicato.context.resume();
    this.audio.music = new Pizzicato.Sound('audio/a-quick-coffee.mp3', () => {
      this.audio.music.loop = true;
      this.audio.music.volume = 0;
    });
    this.audio.lowPass = new Pizzicato.Effects.LowPassFilter({ frequency: 22050, peak: 10 });
    this.audio.music.addEffect(this.audio.lowPass);
    window.audio = this.audio;
    this.$store.commit('scroll/setWhitePage', false);
    this.$store.commit('scroll/setMenuHidden', true);
    const showroomInterval = setInterval(() => {
      this.showroomReady = 'showroom' in window && window.showroom.params && window.showroom.params.ready;
      if (this.showroomReady) {
        clearInterval(showroomInterval);
        setTimeout(() => {
          this.playIntro();
        }, 800);
      }
    }, 200);
    window.test = this;
    window.addEventListener('resize', this.winResize);
    this.winResize();
  },
  created () {
    this.$store.commit('scroll/setMenu', this.menu);
  },
  beforeDestroy () {
    this.$store.commit('scroll/setWhitePage', false);
    this.$store.commit('scroll/setMenuHidden', false);
    window.removeEventListener('reisze', this.winResize);
  }
};
</script>

<style lang="scss">
//
</style>
