import { render, staticRenderFns } from "./ShowroomIVPXS.vue?vue&type=template&id=3ebffb9f&"
import script from "./ShowroomIVPXS.vue?vue&type=script&lang=js&"
export * from "./ShowroomIVPXS.vue?vue&type=script&lang=js&"
import style0 from "./ShowroomIVPXS.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports