<template>
  <div class="selection" :class="{ 'view': view, 'home': home }">
    <div @click="view = true" class="selection__burger" v-if="showButtons">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="selection__container">
      <div class="container-fluid position-relative selection__links">
        <div class="row no-gutters">
          <div class="col-12 col-md-6 selection__link" v-for="room in rooms" :key="room.key">
            <a :href="`${url}#/${room.route}`" class="d-block" :class="{ 'current': current === room.key }">
              <div class="selection__link__image">
                <div><img :src="room.image" :alt="$t(`${room.key}.title`)"></div>
              </div>
              <div class="link__title">
                <div v-html="$t(`${room.key}.title`)"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="close white" @click="view = false" :class="{ 'view': view }" v-if="showButtons">
      <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Selection',
  props: {
    current: { type: String, default: '' },
    showSelection: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    home: { type: Boolean, default: false }
  },
  data () {
    return {
      view: false,
      rooms: [
        {
          key: 'ivarioPro2xs',
          image: 'images/ivario-pro-2xs/ivario-pro-xs-functions-rational.jpg',
          route: 'ivario2xs'
        },
        {
          key: 'ivarioPro2s',
          image: 'images/ivario-pro-2s/ivario-pro-s.jpg',
          route: 'ivarioPro2s'
        },
        {
          key: 'ivarioProL',
          image: 'images/ivario-pro-l/menu.jpg',
          route: 'ivarioProL'
        },
        {
          key: 'ivarioProXL',
          image: 'images/ivario-pro-xl/ivario-pro-xl-open-rational-107027.jpg',
          route: 'ivarioProXL'
        }
      ]
    };
  },
  computed: {
    url: function () {
      const hash = document.location.hash;
      let url = document.location.href;
      if (hash) {
        url = url.substr(0, url.indexOf('#'));
      }
      return url;
    }
  },
  watch: {
    showSelection: function (val) {
      if (val && !this.view) {
        this.view = true;
      }
      if (!val && this.view) {
        this.view = false;
      }
    }
  },
  methods: {
    open: function () {
      this.view = true;
    }
  },
  mounted () {
    if (this.showSelection) {
      this.view = true;
    }
  },
  created () {},
  destroyed () {}
};
</script>
